import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import Button from "./Button.js";

import styles from "../../assets/jss/views/typographyStyle";
import TwitterIcon from "@material-ui/icons/Twitter";

const useStyles = makeStyles((styles) => ({
  root: {
    maxWidth: 600,
    margin: "auto",
    textAlign: "center",
  },
}));

export default function SectionTypography() {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <h1>This is the end</h1>
      <p>
        Thank you for your support on this short journey. Unfortunately, we've
        had to shut down this project for various reasons. Game On was developed
        to help you expand your social circles through sports and enjoy a
        healthier lifestyle.
      </p>
      <Button
        href="https://twitter.com/intent/follow?original_referer=http%3A%2F%2Fgameonapp.com%2F&ref_src=twsrc%5Etfw&screen_name=gameonapp&tw_p=followbutton"
        target="_blank"
        color="twitter"
      >
        <TwitterIcon className={classes.icons} />
      </Button>
    </div>
  );
}
