import logo from "./logo.svg";
// import "./App.css";
import { Provider } from "react-redux";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import store from "./store";
import { history } from "./utils/historyUtils";

import Homepage from "./pages/Homepage";

function App() {
  return (
    // <div className="App">
    //   <header className="App-header">
    //     <img src={logo} className="App-logo" alt="logo" />
    //     <p>
    //       Edit <code>src/App.js</code> and save to reload.
    //     </p>
    //     <a
    //       className="App-link"
    //       href="https://reactjs.org"
    //       target="_blank"
    //       rel="noopener noreferrer"
    //     >
    //       Learn React
    //     </a>
    //   </header>
    // </div>
    <Router history={history}>
      <Provider store={store}>
        <Switch>
          {/* <Post /> */}
          {/* <Newsfeed /> */}
          <Route exact path="/" component={Homepage} />
        </Switch>
      </Provider>
    </Router>
  );
}

export default App;
