import ThemeProvider from "@material-ui/core/styles/MuiThemeProvider";

import React from "react";

import ThankYou from "../components/Home/ThankYou";
import theme from "../theme";
import Typography from "@material-ui/core/Typography";

export default class Home extends React.Component {
  render() {
    return (
      <ThemeProvider theme={theme.theme}>
        <div
          style={{
            // backgroundColor: "#1976d2",
            backgroundColor: "#ffffff",
            backgroundSize: "contain",
            height: "100vh",
            width: "100vw",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              width: "100vw",
              height: "10vh",
            }}
          ></div>
          <div
            style={{
              width: "100vw",
              maxWidth: "100vh",
              height: "100vw",
              maxHeight: "100vh",
              margin: "0 auto",
              fontFamily: "Roboto, Helvetica, Arial, sans-serif",
              display: "flex",
              placeContent: "center",
              placeItems: "center",
            }}
          >
            <ThankYou />
          </div>
        </div>
      </ThemeProvider>
    );
  }
}
